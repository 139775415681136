<template>
  <div class="aiBudgetSuggestion">
    <el-dialog
      title="提示"
      :visible="bidSuggestionVisible"
      width="800px"
      @close="closeBidSuggetion"
    >
      <template slot="title">
        <div class="title-box">
          <div class="title-main">通过调整每次转化费用目标获得更多转化</div>
          <div class="title-sub">通过将每次转化费用目标调高一定的百分比，您获得的转化次数可能会出现下面估算的增幅。</div>
        </div>
      </template>
      <div>
        <div class="table-tips">选择新的平均目标表每次转化费用：</div>
        <div>
          <div class="table-title">
            <div
              v-for="(item, index) in tableTitleArr"
              :key="index"
              class="table-title-item"
            >
              {{ item }}
            </div>
          </div>
          <el-radio-group v-model="selectedOption" @change="changeRadios">
            <el-radio
              v-for="option in options"
              :key="option.id"
              :label="option.budgetAmount"
              style="display: flex"
            >
              <div class="table-radio">
                <div class="table-radio-item">+US${{ option.budgetAmount.toFixed(2) }}</div>
                <div class="table-radio-item">{{ option.conversions.toFixed(2) }}</div>
                <div class="table-radio-item">+US${{ option.eachConverAmount.toFixed(2) }}</div>
                <div class="table-radio-item">+US${{ option.costTotal.toFixed(2) }}</div>
              </div>
            </el-radio>
            <el-radio :label="selfBudgetAmount">
              <el-input
                v-model="selfBudgetAmount"
                class="selection-input"
                size="small"
                clearable
                placeholder="自定义目标值"
                @input="changeSelfBudgetAmount"
              >
                <template slot="prepend">US$</template>
              </el-input>
            </el-radio>
          </el-radio-group>
          <span v-if="!selectedOption" class="table-radio-tips">自定义预算的时候，预算不能为空{{ selectedOption }}</span>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="closeBidSuggetion">取 消</el-button>
        <el-button
          type="primary"
          @click="submitAiSuggestion"
          >采 纳</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { googleRecommendApi, googleApplyRecommendApi } from '@/api/google/adManagement';
const giikinLodash = require('lodash');
export default {
  data() {
    return {
      selectedOption: null,
      options: [],
      resourceName: '',
      tableTitleArr: ['平均目标每次转化费用', '每周转化次数', '每次转化费用', '每周费用'],
      selfBudgetAmount: null,
    };
  },
  props: {
    bidSuggestionVisible: {
      type: Boolean,
      default: false,
    },
    googleTableRow: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    changeSelfBudgetAmount() {
        this.selectedOption = this.selfBudgetAmount;
    },
    changeRadios() {
      console.log('changeRadios', this.selectedOption);
    },
    closeBidSuggetion() {
      this.$emit('closeBidSuggetion');
    },
    async googleRecommend() {
      try {
        const params = {
          campaignId: this.googleTableRow.campaign_id,
          accountId: this.googleTableRow.accountId,
        };
        this.$showLoading();
        const res = await googleRecommendApi(params);
        this.$hideLoading();
        // 暂时先取第一个，后续根据recommedType进行判断是预算还是出价
        const outerOptions = res.data.filter(item => item.recommedType === 'BIDDING_STRATEGY_LEARNING');
        this.resourceName = outerOptions[0].resourceName;
        this.options = outerOptions[0].recommeds;
        this.options.forEach((item, index) => {
          item.id = index + 1;
        });
        const selectIndex = this.options.findIndex((item) => item.recommend);
        if (selectIndex !== -1) {
          this.selectedOption = this.options[selectIndex].budgetAmount;
        }
      } catch {}
    },
    submitAiSuggestion: giikinLodash.debounce(async function () {
      try {
        if (!this.selectedOption) {
          return;
        }
        const params = {
          accountId: this.googleTableRow.accountId,
          resourceName: this.resourceName,
          budget: this.selectedOption,
        };
        await googleApplyRecommendApi(params);
        this.$emit('closeAiSuggetion');
        this.$message.success('采纳成功');
      } catch {}
    }, 500),
  },
  mounted() {
    this.googleRecommend();
  },
};
</script>
<style lang="scss" scoped>
.aiBudgetSuggestion {
  ::v-deep .el-dialog__body {
    padding-top: 10px;
  }
  ::v-deep .el-radio__label {
    padding-left: 5px;
  }
  ::v-deep .el-radio__inner {
    position: relative;
    top: 13px;
  }
  ::v-deep .el-input-group__prepend {
    width: 50px;
  }
}
.title-box {
  border-bottom: 2px solid #e6e6e6;
}
.title-main {
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  margin-bottom: 10px;
}
.title-sub {
  font-size: 14px;
  color: #666666;
  margin-bottom: 10px;
}
.table-tips {
  font-size: 14px;
  color: #666666;
  margin-bottom: 10px;
  font-weight: bold;
}
.table-title {
  display: flex;
  font-weight: bold;
}
.table-title-item {
  width: 200px;
  text-align: left;
}
.table-radio {
  display: flex;
}
.table-radio-item {
  width: 180px;
  text-align: left;
  height: 40px;
  line-height: 40px;
}
.selection-input {
  width: 230px;
  position: relative;
  top: 10px;
}
.table-radio-tips {
    color: #f56c6c;
    position: relative;
    top: 10px;
    left: 20px;
    font-size: 12px;
}
</style>
